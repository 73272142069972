import React from 'react';

import './GridGallery.css';
import cn from 'classnames';

export interface Item {
    key: string;
    imgSrc: string;
    title: string;
    subtitle: string;
    isBig?: boolean;
}

interface Props {
    items: Item[];
}

export const GridGallery: React.FC<Props> = ({ items }) => (
    <div className="GridGallery">
        {items.map(({ key, imgSrc, title, subtitle, isBig }) => (
            <div className={cn('GridGallery-item', isBig && 'GridGallery-big-item')} key={key}>
                <img src={imgSrc} alt="img" className="GridGallery-img" />
                <div className="GridGallery-text">
                    <span className="GridGallery-title">{title}</span>
                    <span className="GridGallery-subtitle">{subtitle}</span>
                </div>
            </div>
        ))}
    </div>
);
